.privacy-policy {
    max-width: 800px;
    margin: 50px auto;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
}

.privacy-policy h1 {
    font-size: 2.5em;
    border-bottom: 2px solid #333;
    padding-bottom: 10px;
    margin-bottom: 30px;
}

.privacy-policy h2 {
    font-size: 2em;
    margin-top: 30px;
}

.privacy-policy ul {
    margin-left: 20px;
    list-style-type: disc;
}

.privacy-policy li {
    margin-top: 10px;
}

.privacy-policy p {
    margin-top: 20px;
}
